import React from 'react'
import Product from "components/features/ThreeColWithSideImage";
import About from "components/hero/TwoColumnWithFeaturesAndTestimonial.js"
import imgSrc1 from "images/srectr-kit-set-00.png"
import imgSrc2 from "images/srectr-kit-set-02.jpeg"
import imgSrc3 from "images/srectr-kit-set-03.jpeg"
import tw from "twin.macro";
import { SectionHeading } from "components/misc/Headings.js";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Image = styled(motion.div)(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`h-64 bg-cover bg-center rounded`
]);
const Column = tw.div``;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const heading = "SRECTR Kit Set"
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;
const subheading = "Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR)"
const itemCode = "SRECTR00010"
const product = "SRECTR Instrument"
const specs = ['1x SRECTR Instrument (Tapered)', 
               '1x SRECTR Instrument (Non-tapered)', 
               '1x L Bar',
              ]
const qtt = "1 set"

const SrectrKitSet = () => {
    return (
        <div>
            <Product heading={heading} subheading={subheading} itemCode={itemCode} 
                     product={product} specs={specs} qtt={qtt} 
                     imgSrc1={imgSrc1} imgSrc2={imgSrc2} imgSrc3={imgSrc3}
            />
            <div style={{display: 'flex',  flexDirection:'column', alignItems:'center'}}>
              <div style={{width: '50%', border: '1px solid black', borderRadius: '5px', margin: '10px'}}>
              <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Material</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>Surgical Grade Stainless Steel</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Instruments</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>SRECTR Instrument (Tapered)<br></br>SRECTR Instrument (Non-tapered)<br></br>L Bar</div>
                </div>
              </div>
            </div>
            <Row>
              <TextColumn>
                <Heading>As seen on</Heading>
                  <a target="_blank" rel="noopener noreferrer" href="https://easily.sinchew.com.my/node/5453/srectr治腕隧道症候群-保护肌腱-减术后神经受损风险?fbclid=IwZXh0bgNhZW0CMTAAAR0DtRJUewSFhK4nbvktY8Oa1z9GMvnFYgJrJFR2oTPRSsekm-JIl2WgsIU_aem_r-_VZ_mpO0J0oH80hU_xYg">
                    <img src="https://www.sinchew.com.my/public/images/Sinchew-i-s.png" width="50%" height="50%" objectFit='cover'/>
                  </a>
              </TextColumn>
            </Row>
            <About
              heading = "About"
              description = "The SRECTR Instrument is a reusable surgical instrument intended for use in SRECTR surgery to treat carpal tunnel syndrome. The medical instrument is used to define a surgical cavity between the flexor retinaculum and the overlying subcutaneous fat and skin for the insertion of an endoscope and ligament cutting tools."
              imageSrc = ""
              imageDecoratorBlob = "true"
              primaryButtonUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20buying%20Instrument%20Set."
              primaryButtonText = "Contact Us"
              buttonRounded = "true"
              features2 = {["Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR)"]}
              features3 = {["Microsurgery", "Hand surgery"]}

              facebookUrl = "https://m.me/MedTechIns"
              whatsAppUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Instrument%20Set."
              emailUrl = "mailto:medicaltechinstruments@gmail.com?subject=Instrument%20Set%20Enquiry&body=I'm%20interested%20in%20the%20Instrument%20Set."
            />
        </div>
    )
}

export default SrectrKitSet
