import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import LandingPage from "pages/LandingPage.js"
import About from "pages/About.js";
import Products from "pages/Products.js";
import Articles from "pages/Articles.js";
import FAQ from "components/faqs/SingleCol.js"
import MicrovesselModel from "pages/MicrovesselModel.js";
import Sutures from "pages/Sutures.js";
import InstrumentSet from "pages/InstrumentSet.js";
import Cart from "pages/Cart.js";
import SrectrInstrument from "pages/SrectrInstrument.js";
import SrectrKitSet from "pages/SrectrKitSet.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "components/headers/light.js";
import Footer from "components/footers/Footer.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import { Helmet, HelmetProvider } from "react-helmet-async";

export default function App() {
  return (
    <HelmetProvider>
      <AnimationRevealPage>  
        <Helmet>
          <title>
            Med Tech Instruments
          </title>
          <meta
            name="description"
            content="Microvessel models, surgical instruments for surgical training. SRECTR instruments for Supraretinacular Endoscopy Carpal Tunnel Release (SRECTR)"
          />

          {/* Facebook meta tags below */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="www.medtechinstruments.com" />
          <meta
            property="og:title"
            content="Med Tech Instruments"
          />
          <meta
            property="og:description"
            content="Microvessel models, surgical instruments for surgical training. SRECTR instruments for Supraretinacular Endoscopy Carpal Tunnel Release (SRECTR)"
          />
        </Helmet>
        <Header />
        <Router>
          <Switch>
            {/* <Route path="/components/:type/:subtype/:name">
              <ComponentRenderer />
            </Route>
            <Route path="/components/:type/:name">
              <ComponentRenderer />
            </Route> */}
            {/* <Route exact path="/cart">
              <Cart />
            </Route> */}
            <Route exact path="/articles">
              <Articles />
            </Route>
            <Route exact path="/srectr-kit-set">
              <SrectrKitSet />
            </Route>
            <Route exact path="/srectr-instrument">
              <SrectrInstrument />
            </Route>
            <Route exact path="/instrument-set">
              <InstrumentSet />
            </Route>
            <Route exact path="/sutures">
              <Sutures />
            </Route>
            <Route exact path="/microvessel-model">
              <MicrovesselModel />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/products">
              <Products />
            </Route>
            <Route exact path="/faq">
              <FAQ />
            </Route>
            <Route path="/home">
              <LandingPage />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </AnimationRevealPage>
    </HelmetProvider>
  );
}