import React from 'react'
import tw from "twin.macro";
import Product from "components/features/ThreeColWithSideImage";
import About from "components/hero/TwoColumnWithFeaturesAndTestimonial.js"
import imgSrc1 from "images/microvessel-model.png"
import imgSrc2 from "images/microvessel-model2.jpg"
import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import styled from "styled-components";

const heading = "Microvessel Model"
const subheading = "Microvascular Anastomosis Training"
const itemCode = "MV100/MV150/MV200"
const product = "Microvessel model 1.0/1.5/2.0mm"
const specs = ['1.0/1.5/2.0mm Diameter', '0.5mm Thickness', 'Silicone rubber']
const qtt = "5cm/pcs"

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

const MicrovesselModel = () => {
    return (
        <div>
            <Product heading={heading} subheading={subheading} itemCode={itemCode} 
                     product={product} specs={specs} qtt={qtt} 
                     imgSrc1={imgSrc1} imgSrc2={imgSrc2}
            />

            <div style={{display: 'flex',  flexDirection:'column', alignItems:'center'}}>
              <div style={{width: '50%', border: '1px solid black', borderRadius: '5px', margin: '10px'}}>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Size</div>
                  <div style={{borderRight: '1px solid black', width: '25%', paddingRight: '5px', textAlign: 'center'}}>1.0 mm</div>
                  <div style={{borderRight: '1px solid black', width: '25%', paddingRight: '5px', textAlign: 'center'}}>1.5 mm</div>
                  <div style={{width: '25%', paddingRight: '5px', textAlign: 'center'}}>2.0 mm</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Color</div>
                  <div style={{borderRight: '1px solid black', width: '25%', paddingRight: '5px', textAlign: 'center', color: 'hotpink', borderBottom: '1px solid black'}}>Pink</div>
                  <div style={{borderRight: '1px solid black', width: '25%', paddingRight: '5px', textAlign: 'center', color: 'red', borderBottom: '1px solid black'}}>Red</div>
                  <div style={{width: '25%', paddingRight: '5px', textAlign: 'center', color: 'blue', borderBottom: '1px solid black'}}>Blue</div>
                </div>

                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Thickness</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>0.5 mm</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Length</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>5 cm</div>
                </div>
              </div>
            </div>

            <StyledResponsiveVideoEmbed
              url="https://www.youtube.com/embed/ph55_94fa-o"
              background="transparent"
            />
            <About
              heading = "About"
              description= "A microvessel model comprising an adventitia (outer layer) and muscle wall (inner layer) to simulate live artery for microvascular anastomosis training. 
                            An ethical replacement of animal tissues for surgical training."
              imageDecoratorBlob = "true"
              primaryButtonUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20buying%20Microvessel%20Model."
              primaryButtonText = "Buy Now"
              buttonRounded = "true"
              features2 = {["Simulation for surgeon"]}
              features3 = {["Microsurgery", "Hand surgery", "Plastic surgery", "Orthopaedic surgery"]}

              facebookUrl = "https://m.me/MedTechIns"
              whatsAppUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Microvessel%20Model."
              emailUrl = "mailto:medicaltechinstruments@gmail.com?
                          subject=Microvessel%20Model%20Enquiry
                          &body=I'm%20interested%20in%20the%20Microvessel%20Model.
                         "
            />
        </div>
    )
}

export default MicrovesselModel
