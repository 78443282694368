import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import Article1 from "images/article-01.jpg";
import InstrumentSetImage from "images/instrument-titanium.jpeg";
import SuturesImage from "images/sutures-packaging.png";
import SrectrImage from "images/srectr-instrument.jpeg";
import SrectrKitSetImage from "images/srectr-kit-set-02.jpeg";
import { SectionHeading } from "components/misc/Headings.js";

const ImageContainer = styled.div`
  ${tw`rounded overflow-hidden`}
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  ${tw`object-cover object-center`}
`;

const Column = tw.div``;
const Row = tw.div`flex flex-col lg:flex-row justify-between items-center lg:pt-16 max-w-screen-2xl mx-auto sm:px-8`;
const TextColumn = tw(Column)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(SectionHeading)`text-left text-primary-900 leading-snug xl:text-6xl`;

const Articles = () => {
  return (
    <div>
      <TextColumn>
        <Heading>As Seen On</Heading>
      </TextColumn>
      <ImageContainer>
      <a target="_blank" rel="noopener noreferrer" href="https://easily.sinchew.com.my/node/5453/srectr治腕隧道症候群-保护肌腱-减术后神经受损风险?fbclid=IwZXh0bgNhZW0CMTAAAR0DtRJUewSFhK4nbvktY8Oa1z9GMvnFYgJrJFR2oTPRSsekm-JIl2WgsIU_aem_r-_VZ_mpO0J0oH80hU_xYg">
                    <img src="https://www.sinchew.com.my/public/images/Sinchew-i-s.png" width="15%" height="15%" objectFit='cover'/>
                  </a>
        <Image src={Article1} alt="Article Image" />
      </ImageContainer>
    </div>
  );
};

export default Articles;
