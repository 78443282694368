import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion.custom(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion.custom(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;


export default ({
  subheading = "Frequenty Asked Questions",
  heading = "FAQ",
  description = "Do drop us a message for any further enquiries.",
  faqs = [
    {
      question: "Which countries do you ship to?",
      answer: ["We ship to all international destinations. If you have any inquiries about delivery destinations and restrictions, please contact us directly."]
    },
    {
      question: "How much is the shipping fee?",
      answer:
        ["Shipping fees vary depending on the delivery location. We will provide quotation inclusive of shipping fee for your reference."]
    },
    {
      question: "When will you ship my order?",
      answer:
        ["We will begin to process your order upon receipt of your payment, and you will then receive an order confirmation email from us."]
    },
    {
      question: "What is the payment method?",
      answer: 
        ["Currently, we only accept telegraphic transfer. Please refer to our account details as follows: ", "_", "Beneficiary Name: MED TECH INSTRUMENTS SDN BHD", "Beneficiary Account Number: 8010488831",
        "Beneficiary Bank: CIMB BANK BERHAD", "Bank Address: 22, Jalan PJS 11/28A, Bandar Sunway, 46100 Petaling Jaya, Selangor, Malaysia", "Swift Code: CIBBMYKL",
        "_", "***Please email to us with a scan copy/image of bank in details.", "• Please note all Telegraphic Transfer service charges is to be borne by customer.", "• Local taxes may be subjected to goods to be borne by customer."]
    },
    {
      question: "How do I check delivery status of my order?",
      answer:
        ["Once we have processed your online order, you will receive an order confirmation email with delivery tracking link. ",
         "_", "Please ensure accurate and full delivery address details are entered during order placement to avoid delivery failure."]
    },
    {
      question: "How do I amend/cancel my order?",
      answer:
        ["Please send in your amendment by contacting our customer care team by Whatsapp (+6011-2095 3453) from 9am - 6pm Mondays-Fridays, or email us at medicaltechinstruments@gmail.com",
         "_", "If your order had been picked up by the courier, you will not be able to cancel the order. We usually process orders within 24 hours after payment."]
    },
    {
      question: "How do I return or exchange an item?",
      answer:
        ["We do not offer refunds or exchanges. For defective items, please email us at medicaltechinstruments@gmail.com within 3 days of receipt of your purchase."]
    },
    {
      question: "How do I place an order?",
      answer:
        ["You can place your order by contacting us on our Facebook, WhatsApp or Email."]
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <div>
      <Container>
        <ContentWithPaddingXl>
          <Column>
            <HeaderContent>
              {subheading && <Subheading>{subheading}</Subheading>}
              <Heading>{heading}</Heading>
              {description && <Description>{description}</Description>}
            </HeaderContent>
            <FAQSContainer>
              {faqs.map((faq, index) => (
                <FAQ
                  key={index}
                  onClick={() => {
                    toggleQuestion(index);
                  }}
                  className="group"
                >
                  <Question>
                    <QuestionText>{faq.question}</QuestionText>
                    <QuestionToggleIcon
                      variants={{
                        collapsed: { rotate: 0 },
                        open: { rotate: -180 }
                      }}
                      initial="collapsed"
                      animate={activeQuestionIndex === index ? "open" : "collapsed"}
                      transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                    >
                      <ChevronDownIcon />
                    </QuestionToggleIcon>
                  </Question>
                  <Answer
                    variants={{
                      open: { opacity: 1, height: "auto", marginTop: "16px" },
                      collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    {faq.answer.map((answer) => {
                      return <p>{answer}</p>
                    })}
                  </Answer>
                </FAQ>
              ))}
            </FAQSContainer>
          </Column>
        </ContentWithPaddingXl>
        <DecoratorBlob1/>
        <DecoratorBlob2 />
      </Container>
    </div>
  );
};
