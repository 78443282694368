import React from 'react'
import Product from "components/features/ThreeColWithSideImage";
import About from "components/hero/TwoColumnWithFeaturesAndTestimonial.js"
import imgSrc1 from "images/srectr-instrument.jpeg"
import imgSrc2 from "images/srectr-catalogue.png"
// import imgSrc2 from "www.youtube.com"

const heading = "SRECTR Instrument"
const subheading = "Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR)"
const itemCode = "SRECTR00010"
const product = "SRECTR Instrument"
const specs = ['1x SRECTR Instrument (Tapered)', 
               '1x SRECTR Instrument (Non-tapered)', 
               '1x L Bar',
              ]
const qtt = "1 set"

const SrectrInstrument = () => {
    return (
        <div>
            <Product heading={heading} subheading={subheading} itemCode={itemCode} 
                     product={product} specs={specs} qtt={qtt} 
                     imgSrc1={imgSrc1} imgSrc2={imgSrc2} //imgSrc2={imgSrc2}
            />
            <div style={{display: 'flex',  flexDirection:'column', alignItems:'center'}}>
              <div style={{width: '50%', border: '1px solid black', borderRadius: '5px', margin: '10px'}}>
              <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Material</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>Surgical Grade Stainless Steel</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Instruments</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center'}}>SRECTR Instrument (Tapered)<br></br>SRECTR Instrument (Non-tapered)<br></br>L Bar</div>
                </div>
              </div>
            </div>
            <About
              heading = "About"
              description = "The SRECTR Instrument is a reusable surgical instrument intended for use in SRECTR surgery to treat carpal tunnel syndrome. The medical instrument is used to define a surgical cavity between the flexor retinaculum and the overlying subcutaneous fat and skin for the insertion of an endoscope and ligament cutting tools."
              imageSrc = ""
              imageDecoratorBlob = "true"
              primaryButtonUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20buying%20Instrument%20Set."
              primaryButtonText = "Buy Now"
              buttonRounded = "true"
              features2 = {["Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR)"]}
              features3 = {["Microsurgery", "Hand surgery"]}

              facebookUrl = "https://m.me/MedTechIns"
              whatsAppUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Instrument%20Set."
              emailUrl = "mailto:medicaltechinstruments@gmail.com?subject=Instrument%20Set%20Enquiry&body=I'm%20interested%20in%20the%20Instrument%20Set."
            />
        </div>
    )
}

export default SrectrInstrument
