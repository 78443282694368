import React from "react";
import tw from "twin.macro";
import MainFeature1 from "components/features/TwoCol.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <div>
      <MainFeature1
        subheading={<Subheading>About Us</Subheading>}
        heading="Company Profile"
        description="Established in 2020, Med Tech is a surgical instruments and materials technology company."
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
    </div>
  );
};
