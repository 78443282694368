import React from "react";
import ContactUsForm from "components/forms/SimpleContactUs.js";
import Products from "pages/Products.js"
import Articles from "pages/Articles.js"

import Tableau from "pages/Tableau.js"

import Features from "components/features/TwoColWithButton"
import Testimonial from "components/testimonials/SimplePrimaryBackground"

import image from "images/microvessel-model-1mm2mm.png"
import image2 from "images/mostafa.png"


import ResponsiveVideoEmbed from "helpers/ResponsiveVideoEmbed.js";
import tw from "twin.macro";
import styled from "styled-components";
import { Tab } from "@material-ui/core";

const unknownPic = "https://thumbs.dreamstime.com/b/default-avatar-profile-flat-icon-social-media-user-vector-portrait-unknown-human-image-default-avatar-profile-flat-icon-184330869.jpg"

const heading = "Now available in 1.5mm"
const subheading = "Microvascular Anastomosis Training"
const description = "Our microvessel model now comes in 1.0, 1.5 and 2.0mm in size. 2.0mm is suitable for warm up and can be done with loupe; 1.0mm requires microscope."
const buttonUrl = "/microvessel-model"

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;

export default () => (
  <div>
    <Features 
      subheading ={subheading}
      heading = {heading}
      description = {description}
      primaryButtonUrl = {buttonUrl}
      imageSrc = {image}
    />
    
    <StyledResponsiveVideoEmbed
      url="https://www.youtube.com/embed/ph55_94fa-o"
      background="transparent"
    />

    <Products />
    <Testimonial 
      subheading = ""
      heading = "Testimonials"
      description = "From surgeons and doctors who used and tested our microvessel model."
      testimonials = {
        [
        {
          customerName: "Dr. Steven Lo",
          customerProfile: "Plastic Surgeon, Glasgow",
          imageSrc: unknownPic,
          quote:
            "Good stimulator for micro training."
        },
        {
          customerName: "Mostafa El-masry",
          customerProfile: "Linköping, Sweden",
          imageSrc: image2,
          quote:
            "Very realistic micro-vessels model, I strongly recommend it for training purposes. The diameter  of the vessel model is very similar to a real situation, the double layer (simulating adventitia and vessel wall) is an advantage foe trainees. I think the availability of the vessel model at home eases the training process and allow the trainees to practice more. The sense that the model is very near to reality under the training session is a very big advantage."
        }
      ]
      }
    />
    <ContactUsForm />
    {/* <Tableau /> */}
  </div>
);
