import React from 'react'
import Product from "components/features/ThreeColWithSideImage";
import About from "components/hero/TwoColumnWithFeaturesAndTestimonial.js"
import imgSrc1 from "images/instrument-titanium.jpeg"
import imgSrc2 from "images/instrument-stainless-steel.jpeg"

const heading = "Instrument Set"
const subheading = "Microvascular Anastomosis Training"
const itemCode = "itemCode"
const product = "Instrument set"
const specs = ['2x Jeweller forceps (0.15mm)', 
               '1x Needle holder', 
               '1x Curved scissors',
              ]
const qtt = "1 set"

const InstrumentSet = () => {
    return (
        <div>
            <Product heading={heading} subheading={subheading} itemCode={itemCode} 
                     product={product} specs={specs} qtt={qtt} 
                     imgSrc1={imgSrc1} imgSrc2={imgSrc2}
            />

            <div style={{display: 'flex',  flexDirection:'column', alignItems:'center'}}>
              <div style={{width: '50%', border: '1px solid black', borderRadius: '5px', margin: '10px'}}>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Material</div>
                  <div style={{borderRight: '1px solid black', width: '37.5%', paddingRight: '5px', textAlign: 'center'}}>Stainless Steel</div>
                  <div style={{width: '37.5%', paddingRight: '5px', textAlign: 'center'}}>Titanium</div>
                </div>
                <div style={{display: 'flex'}}>
                  <div style={{width: '25%', borderRight: '1px solid black', paddingRight: '5px', textAlign: 'center'}}>Instruments</div>
                  <div style={{width: '75%', paddingRight: '5px', textAlign: 'center', borderTop: '1px solid black'}}>Jeweller's forceps (0.15/0.30mm)<br></br>Needle holder<br></br>scissors</div>
                </div>
              </div>
            </div>
            <About
              heading = "About"
              description = "Instrument set of 4 for microvascular anastomosis training. Available in stainless steel and titanium."
              imageSrc = ""
              imageDecoratorBlob = "true"
              primaryButtonUrl = "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20buying%20Instrument%20Set."
              primaryButtonText = "Buy Now"
              buttonRounded = "true"
              features2 = {["Simulation for surgeon"]}
              features3 = {["Microsurgery", "Hand surgery", "Plastic surgery", "Orthopaedic surgery"]}

              facebookUrl = "https://m.me/MedTechIns"
              whatsAppUrl = "https://wa.me/601120953453?text=Hi%2C%20I%27m%20interested%20in%20Instrument%20Set."
              emailUrl = "mailto:medicaltechinstruments@gmail.com?
                          subject=Instrument%20Set%20Enquiry
                          &body=I'm%20interested%20in%20the%20Instrument%20Set.
                         "
            />
        </div>
    )
}

export default InstrumentSet
