/* A simple iframe wrapper that makes 16:9 responsive embed */
import React from 'react';

export default ({ url, background="black", className="video" }) => {
  return (

    <div
      align="center"
      className={className}
      style={{
        position: "relative",
        background: background,
        paddingBottom: "56.25%", /* 16:9 */
        width: "100%",
        overflow: "hidden",
        paddingTop: 25,
        height: 0,
        
      }}
    >
      <iframe 
        style={{
          position: "absolute",
          width: "70%",
          height: "80%",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          marginLeft: "15%" // (100% - width)/2
        }}
        src={url} 
        frameborder="1px">
      </iframe>
    </div>

  );
};
