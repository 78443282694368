import React from "react";
import Product from "components/features/TwoColSingleFeatureWithStats2.js"

import MicrovesselModelImage from "images/microvessel-model.png"
import InstrumentSetImage from "images/instrument-titanium.jpeg"
import SuturesImage from "images/sutures-packaging.png"
import SrectrImage from "images/srectr-instrument.jpeg"
import SrectrKitSetImage from "images/srectr-kit-set-02.jpeg"

const products = [
  {
    subheading: "Microvascular Anastomosis Training",
    heading: "Microvessel Model",
    description: "A microvessel model comprising an adventitia (outer layer) and muscle wall (inner layer) to simulate live artery for microvascular anastomosis training. An ethical replacement of animal tissues for surgical training.",
    buttonUrl: "/microvessel-model",
    facebookUrl: "https://m.me/MedTechIns",
    whatsAppUrl: "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Microvessel%20Model.",
    emailUrl: "mailto:medicaltechinstruments@gmail.com?subject=Microvessel%20Model%20Enquiry&body=I'm%20interested%20in%20the%20Microvessel%20Model.",
    statistics: [ 
      {key: "Wall Thickness", value: "0.1mm"},
      {key: "Peelable", value: "Adventitia"},
      {key: "1.0, 1.5, 2.0mm", value: "Diameter"}
    ],
    imageSrc:MicrovesselModelImage
  },
  {
    subheading: "Microvascular Anastomosis Training",
    heading: "Sutures",
    description: "Sutures comprising of suture thread with needle.",
    buttonUrl: "/sutures",
    facebookUrl: "https://m.me/MedTechIns",
    whatsAppUrl: "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Sutures.",
    emailUrl: "mailto:medicaltechinstruments@gmail.com?subject=Sutures%20Enquiry&body=I'm%20interested%20in%20the%20Sutures.",
    statistics: [ 
      {key: "Size", value:"10/0"},
      {key: "Double Needle", value: "3/8 ⚫ 0.7×4"},
      {key: "Nylon threads", value: "Black"}
    ],
    imageSrc:SuturesImage
  },

  {
    subheading: "Microvascular Anastomosis Training",
    heading: "Instrument set",
    description: "Instrument set of 4 for microvascular anastomosis training. Available in stainless steel and titanium.",
    buttonUrl: "/instrument-set",
    facebookUrl: "https://m.me/MedTechIns",
    whatsAppUrl: "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20Instrument%20Set.",
    emailUrl: "mailto:medicaltechinstruments@gmail.com?subject=Instrument%20Set%20Enquiry&body=I'm%20interested%20in%20the%20Instrument%20Set.",
    statistics: [ 
      {key: "Material", value: "Stainless Steel/Titanium"},
      {key: "Instruments", value: "4"}
    ],
    imageSrc: InstrumentSetImage
  },
  {
    subheading: "Supraretinacular Endoscopic Carpal Tunnel Release",
    heading: "SRECTR Kit Set",
    description: "A kit set comprising SRECTR Instruments, 2.4mm Endoscope and 3.0mm Endoscopic Scissors for Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR). Reduces the risk of iatrogenic injury to the median nerve.",
    buttonUrl: "/srectr-kit-set",
    facebookUrl: "https://m.me/MedTechIns",
    whatsAppUrl: "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20SRECTR%20Instrument.",
    emailUrl: "mailto:medicaltechinstruments@gmail.com?subject=SRECTR%20Retractor%20+%20L%20Bar&body=I'm%20interested%20in%20the%20SRECTR%20Retractor%20+%20L%20Bar.",
    statistics: [ 
      {key: "Material", value:"Surgical Stainless Steel"},
      {key: "Grip Handle", value:"Ergonomic"}
    ],
    imageSrc: SrectrKitSetImage
  }
  // ,{
  //   subheading: "Supraretinacular Endoscopic Carpal Tunnel Release",
  //   heading: "SRECTR Instrument",
  //   description: "A set of instruments comprising of two retractors (tapered & non-tapered) and an L Bar for Supraretinacular Endoscopic Carpal Tunnel Release (SRECTR). Reduces the risk of iatrogenic injury to the median nerve.",
  //   buttonUrl: "/srectr-instrument",
  //   facebookUrl: "https://m.me/MedTechIns",
  //   whatsAppUrl: "https://wa.me/60182888308?text=Hi%2C%20I%27m%20interested%20in%20SRECTR%20Instrument.",
  //   emailUrl: "mailto:medicaltechinstruments@gmail.com?subject=SRECTR%20Retractor%20+%20L%20Bar&body=I'm%20interested%20in%20the%20SRECTR%20Retractor%20+%20L%20Bar.",
  //   statistics: [ 
  //     {key: "Material", value:"Surgical Stainless Steel"},
  //     {key: "Grip Handle", value:"Ergonomic"}
  //   ],
  //   imageSrc: SrectrImage
  // }
]

const Products = () => {
  return( 
  <div>
    {products.map((product, index) => (
      <Product 
        key={index}                       subheading={product.subheading}
        heading={product.heading}         description={product.description}
        buttonUrl={product.buttonUrl}     facebookUrl={product.facebookUrl}
        whatsAppUrl={product.whatsAppUrl} emailUrl={product.emailUrl}
        statistics={product.statistics}   imageSrc={product.imageSrc}
        />
    ))}
  </div>
  )
}

export default Products